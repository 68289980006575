import { httpResponseHandler } from 'src/http/http-response-handler';

import { HttpTypes } from '../../http';

export class AuthRepository {
  constructor(private api: HttpTypes.HttpClient) {}

  async authLogin(email: string, password: string) {
    const request: HttpTypes.HttpRequest = {
      url: 'auth/login',
      method: 'post',
      body: {
        email,
        password,
      },
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async authLogout(userId: number) {
    const request: HttpTypes.HttpRequest = {
      url: `auth/logout/${userId}`,
      method: 'post',
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async verifyAccessToken(accessToken: string) {
    const request: HttpTypes.HttpRequest = {
      url: 'auth/verifyAccessToken',
      method: 'post',
      body: {
        accessToken,
      },
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }
}
