import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  version?: 'white' | 'blue';
  width?: number;
  height?: number | string;
  url?: string;
  customLogo?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(

  ({ disabledLink = false, version = 'white', height = 100, url = '/orders', width = 100, customLogo, sx, ...other }, ref) => {
    const theme = useTheme();
    let logoColor;
    if(theme.palette.mode === 'dark')
    {
      logoColor = "/logo/logo_full_white.png"
    } else {
      logoColor = "/logo/logo_full_blue.png"
    }
    const logo = (
      <Box
        ref={ref}
        component="img"
        src={customLogo || logoColor}
        sx={{ width, height, cursor: 'pointer', ...sx }}
        {...other}
      />
    );

    if (disabledLink) {
      return logo;
    }


    return (
      <Link component={RouterLink} href={url} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
