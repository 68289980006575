import { Box, useTheme } from '@mui/material';

import Header from '../header/header';

type Props = {
  children: React.ReactNode;
};

export default function DefaultLayout({ children }: Props) {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />

      <Box
        bgcolor={theme.palette.background.neutral}
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: 12, md: 12 },
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
