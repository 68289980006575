import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useTheme } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { useAuthStore } from 'src/auth/auth.store';
import { useAuthStore as useAuthStore2 } from 'src/stores/auth/auth.store';

export const useHeader = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const responsive = useResponsive;
  const navigate = useNavigate();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { clearSession } = useAuthStore();
  const { postUserLogout } = useAuthStore2();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      setOpenConfirmDialog(false);
      await postUserLogout(clearSession);
      navigate('/auth/login');
    } catch (error) {
      navigate('/auth/login');
    }
  };

  return {
    handleMenu,
    handleClose,
    responsive,
    theme,
    anchorEl,
    handleLogout,
    openConfirmDialog,
    setOpenConfirmDialog,
  };
};
