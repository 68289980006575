import { useNavigate } from 'react-router';

import { AxiosHttpClient } from 'src/http';
import { API_URL } from 'src/config-global';
import { useUserStore } from 'src/stores/user/user.store';
import { UserRepository } from 'src/repositories/user/user.repository';

import { useAuthStore } from './auth.store';

export const useAuth = () => {
  const { accessToken, refreshToken, setIsAuthenticated, clearSession } = useAuthStore();
  const { user } = useUserStore();
  const navigate = useNavigate();
  const api = new AxiosHttpClient(API_URL);

  const repository = new UserRepository(api);

  const initializeAuth = async () => {
    if (accessToken && refreshToken) {
      const response = await repository.verifyAccessToken(accessToken, user?.id);
      if (response.isLeft()) {
        clearSession();
        navigate('/auth/login');
      }
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate('/auth/login');
    }
  };

  const useAuthHook: any = {
    initializeAuth,
  };

  return useAuthHook;
};
