import { create } from 'zustand';

import { AxiosHttpClient } from 'src/http';
import { API_URL } from 'src/config-global';
import { AuthRepository } from 'src/repositories/auth/auth.repository';

type States = {
  isLoading: boolean;
  user?: UserType;
};

interface UserType {
  id: number;
  nome: string;
  email: string;
  senha: string;
  permissao: string;
  status: string;
  uf?: string;
}

type postUserLoginParams = {
  email: string;
  password: string;
  setSession: (accessToken: string, refreshToken: string, user: UserType) => void;
};

type Actions = {
  postUserLogin: ({ email, password, setSession }: postUserLoginParams) => Promise<void>;
  postUserLogout: (clearSession: () => void) => Promise<void>;
};

type UserStore = Actions & States;

const initialValues: States = {
  isLoading: false,
  user: {
    id: 0,
    nome: '',
    email: '',
    senha: '',
    permissao: '',
    status: '',
  },
};

// const api = new AxiosHttpClient('http://localhost:2020');
const api = new AxiosHttpClient(API_URL);

const repository = new AuthRepository(api);

export const useAuthStore = create<UserStore>((set: any, get: any) => ({
  ...initialValues,

  postUserLogin: async ({ email, password, setSession }: postUserLoginParams) => {
    set({ isLoading: true });
    const response = await repository.authLogin(email, password);
    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }
    setSession(response.value.access_token, response.value.refresh_token, response.value.user);
    set({ user: response.value.user });
    set({ isLoading: false });
  },

  postUserLogout: async (clearSession: () => void) => {
    const { user } = get();
    const response = await repository.authLogout(user.id);
    if (response.isLeft()) {
      throw new Error(response.value.message);
    }
    clearSession();
  },
}));
