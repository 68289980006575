import React, { useState } from 'react';

import { Box, useTheme, useMediaQuery } from '@mui/material';

import { useOrderDetails } from 'src/stores/orders/order-details/order-details.store';

import Header from '../header/header';

export interface OrderDetailsLayoutProps {
  children: React.ReactNode;
}

export default function OrderDetailsLayout({ children }: OrderDetailsLayoutProps) {
  const { setQuickViewDrawerState } = useOrderDetails();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [hovering, setHovering] = useState(false);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);

  const handleMouseEnter = () => {
    if (isDesktop) {
      setHovering(true);
      setQuickViewDrawerState(true);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartX !== null && e.touches[0].clientX - touchStartX > 50) {
      setQuickViewDrawerState(true);
      setTouchStartX(null);
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
  };

  return (
    <Box
      sx={{ display: 'flex', background: theme.palette.background.neutral, overflow: 'hidden' }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Box
        sx={{
          width: '6px',
          height: '100%',
          bgcolor: '#0d1e30',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          transition: 'background-color 0.3s',
          backgroundColor: hovering ? '#0D1E6C' : '#0D1E6C',
        }}
        onMouseEnter={handleMouseEnter}
        onClick={() => setQuickViewDrawerState(true)}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Header />
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
