import React, { lazy, useRef, Suspense } from 'react';
import { render, RenderResult } from '@testing-library/react';
import { Navigate, useRoutes, MemoryRouter } from 'react-router-dom';

import AuthLayout from 'src/layouts/auth/auth';
import { PATH_AFTER_LOGIN } from 'src/config-global';

import Scrollbar from 'src/components/scrollbar/scrollbar';
import { SplashScreen } from 'src/components/loading-screen';

import { AuthGuard } from '../../auth/guard';
import { DefaultLayout, OrderDetailsLayout } from '../../layouts/index';

// import { authRoutes } from './auth';

import { paths } from '../paths';
import { errorRoutes } from './error';

// PLOP.JS COMMENT BELOW FOR SCRIPTS, PLEASE DON'T REMOVE
// PAGE_IMPORTS
const Kanban = lazy(() => import("../../modules/kanban/kanban/kanban"));
const Settings = lazy(() => import('../../modules/settings/settings/settings'));
const Login = lazy(() => import('../../modules/auth/login/login'));
const Orders = lazy(() => import('../../modules/orders/table/orders'));
const OrderDetails = lazy(() => import('../../modules/orders/order-details/order-details'));
const Users = lazy(() => import('../../modules/users/users/users'));
const Insurance = lazy(() => import('../../modules/insurance/insurance/insurance'));
const Installers = lazy(() => import('../../modules/installers/installers/installers'));
const InstallerDetails = lazy(
  () => import('../../modules/installers/installer-details/installer-details')
);
const Cycles = lazy(() => import('../../modules/cycles/cycles/cycles'));
const InsuranceCycle = lazy(
  () => import('../../modules/insurance-cycle/insurance-cycle/insurance-cycle')
);
const Notifications = lazy(() => import('../../modules/notifications/notifications/table'));

export const renderWithRouter = (ui: React.ReactElement): RenderResult =>
  render(<MemoryRouter>{ui}</MemoryRouter>);

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Navigate to={PATH_AFTER_LOGIN} replace />
          </AuthGuard>
        </Suspense>
      ),
    },

    { path: '*', element: <Navigate to="/404" replace /> },

    ...errorRoutes,

    // PLOP.JS COMMENT FOR BELOW SCRIPTS, PLEASE DON'T REMOVE
    // RELATION_PATH_PAGES
    {
      path: paths.kanban.root, element:
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar ref={null}>
              <DefaultLayout>
                <Kanban />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
    },

    {
      path: paths.settings.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar>
              <DefaultLayout>
                <Settings />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      )
    },
    {
      path: paths.notifications.root, element: (
        <DefaultLayout>
          <Notifications />
        </DefaultLayout>
      ),
    },

    {
      path: paths.insuranceCycle.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar ref={useRef(null)}>
              <DefaultLayout>
                <InsuranceCycle />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      ),
    },

    {
      path: paths.auth.login,
      element: (
        <AuthLayout>
          <Login />
        </AuthLayout>
      ),
    },

    {
      path: paths.orders.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            {/* TODO: Deslocar a Scrollbar para começar "em baixo" do Header. */}
            <Scrollbar ref={useRef(null)}>
              <DefaultLayout>
                <Orders />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      ),
    },

    {
      path: `${paths.orders.root}/details/:id?`,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <OrderDetailsLayout>
              <OrderDetails />
            </OrderDetailsLayout>
          </AuthGuard>
        </Suspense>
      ),
    },

    {
      path: paths.users.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar ref={useRef(null)}>
              <DefaultLayout>
                <Users />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      ),
    },

    {
      path: paths.insurance.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          {/* <AuthGuard> */}
          <Scrollbar ref={useRef(null)}>
            <DefaultLayout>
              <Insurance />
            </DefaultLayout>
          </Scrollbar>
          {/* </AuthGuard> */}
        </Suspense>
      ),
    },

    {
      path: paths.installers.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar>
              <DefaultLayout>
                <Installers />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      ),
    },

    {
      path: paths.installers.details(':id'),
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar>
              <DefaultLayout>
                <InstallerDetails />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      ),
    },

    {
      path: paths.cycles.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar>
              <DefaultLayout>
                <Cycles />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      ),
    },

    {
      path: paths.notifications.root,
      element: (
        <Suspense fallback={<SplashScreen />}>
          <AuthGuard>
            <Scrollbar>
              <DefaultLayout>
                <Notifications />
              </DefaultLayout>
            </Scrollbar>
          </AuthGuard>
        </Suspense>
      ),
    },
  ]);
}
