export const HEADER = {
  W_MOBILE: '100%',
  W_DESKTOP: 1152,
  H_MOBILE: 64,
  H_DESKTOP: 64,
  H_DESKTOP_OFFSET: 64 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
