import { persist } from 'zustand/middleware';
import { create, StoreApi, UseBoundStore } from 'zustand';

interface UserType {
  id: number;
  nome: string;
  email: string;
  permissao: string;
  status: string;
}

type States = {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  userRole: string;
  user?: UserType;
};

type Actions = {
  setAccessToken: (accessToken: string | undefined) => void;
  setRefreshToken: (refreshToken: string | undefined) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setSession: (accessTokenParam: string, refreshTokenParam: string, user: UserType) => void;
  clearSession: () => void;
  setIsLoading: (loadingState: boolean) => void;
  setUserRole: (role: string) => void;
};

type AuthStore = States & Actions;

export const useAuthStore: UseBoundStore<StoreApi<AuthStore>> = create(
  persist<any>(
    (set) => ({
      accessToken: undefined,
      refreshToken: undefined,
      isAuthenticated: false,
      user: undefined,
      isLoading: false,
      userRole: '',
      setAccessToken: (accessToken: string | undefined) => set({ accessToken }),
      setRefreshToken: (refreshToken: string | undefined) => set({ refreshToken }),
      setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated }),
      setIsLoading: (loadingState: boolean) => set({ isLoading: loadingState }),
      clearSession: () => {
        set({ accessToken: undefined, refreshToken: undefined, isAuthenticated: false, user: undefined, userRole: '' });
      },
      setSession: (accessTokenParam: string, refreshTokenParam: string, user: UserType) => {
        if (accessTokenParam && refreshTokenParam) {
          set({ accessToken: accessTokenParam, refreshToken: refreshTokenParam, user, isAuthenticated: true });
        }
      },
      setUserRole: (role: string) => set({ userRole: role }),
    }),
    {
      name: 'authentication-store',
      partialize: (state) => ({
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
      }),
    }
  )
);
