/* eslint-disable no-restricted-syntax */

type MessageError = {
  key: string;
  value: string | ((matches: RegExpMatchArray) => string);
};

export const messagesError: MessageError[] = [
  {
    key: 'Erro',
    value: 'Mensagem',
  },
];

export function responseMessage(err: string | string[]): string {
  if (typeof err === 'string') {
    return translateErrorMessage(err);
  }

  const translatedMessages: string[] = err?.map(translateErrorMessage);

  return translatedMessages?.join(', ');
}

function translateErrorMessage(err: string): string {
  for (const { key, value } of messagesError) {
    const matches = err.match(key);
    if (matches) {
      return buildMessage(matches, value);
    }
  }

  return err;
}

function buildMessage(
  matches: RegExpMatchArray,
  value: string | ((matches: RegExpMatchArray) => string)
): string {
  if (typeof value === 'function') {
    return value(matches);
  }
  return value.replace(/\$(\d+)/g, (_, index) => matches[index]);
}
