const ROOTS = {
  AUTH: '/auth',
  ORDERS: '/orders',
  KANBAN: '/kanban',
  USERS: '/users',
  INSURANCE: '/insurance',
  INSTALLERS: '/installers',
  CYCLES: '/cycles',
  NOTIFICATIONS: '/notifications',
  SETTINGS: '/settings',
};

export const paths = {
  root: '/',
  auth: {
    root: ROOTS.AUTH,
    login: `${ROOTS.AUTH}/login`,
    refreshTokens: (id?: number) => `auth/refreshToken/${id}`, // TODO: SEMPRE PREENCHER EM PROJETOS NOVOS
  },

  // PLOP.JS COMMENT BELOW FOR SCRIPTS, PLEASE DON'T REMOVE
  // PAGES
      
  users:{
    root: ROOTS.USERS,
  },

  insurance:{
    root: ROOTS.INSURANCE,
  },
      
  kanban: {
    root: ROOTS.KANBAN,
  },
  orderDetails: {
    root: '/orderDetails/:id?',
  },

  portal: {
    root: '/portal',
  },
  orders: {
    root: ROOTS.ORDERS,
    details: (id?: string) => id ? `${ROOTS.ORDERS}/details/${id}` : `${ROOTS.ORDERS}/details`,
  },

  installers: {
    root: ROOTS.INSTALLERS,
    details: (id: string) => `${ROOTS.INSTALLERS}/details/${id}`,
  },

  cycles: {
    root: ROOTS.CYCLES,
  },

  insuranceCycle: {
    root: `${ROOTS.INSURANCE}${ROOTS.CYCLES}`,
  },

  notifications: {
    root: ROOTS.NOTIFICATIONS,
  },

  settings: {
    root: ROOTS.SETTINGS,
  },

  error: {
    notFound: '/404',
    forbidden: '/403',
    serverError: '/500',
  },
};
