import React, { useState } from 'react';

import { AccountCircle } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  Menu,
  Stack,
  Badge,
  AppBar,
  Drawer,
  Toolbar,
  MenuItem,
  Container,
  IconButton,
  Typography,
  badgeClasses,
} from '@mui/material';

import { useUserStore } from 'src/stores/user/user.store';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import ConfirmDialog from 'src/components/custom-dialog/confirm-dialog';

import HeaderTabs from './tabs/tabs';
import { HEADER } from '../config-layout';
import { useHeader } from './header.hook';

export interface HeaderProps { }

export default function Header(Props: HeaderProps) {
  const {
    theme,
    handleMenu,
    anchorEl,
    handleClose,
    responsive,
    handleLogout,
    openConfirmDialog,
    setOpenConfirmDialog,
  } = useHeader();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUserStore();

  const handleOpenDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          gap: 3,
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          backgroundColor: theme.palette.primary.darker,
        }}
      >
        <Container maxWidth="xl" sx={{ height: '100%' }}>
          <Stack direction="row" alignItems="center" height={1} justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={4} justifyContent="space-between" sx={{ height: 1, width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Badge
                  sx={{
                    [`& .${badgeClasses.badge}`]: {
                      top: 8,
                      right: -16,
                    },
                  }}
                >
                  {responsive('down', 'sm') ? (
                    <>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Iconify
                          color="white"
                          height={35}
                          width={35}
                          icon="material-symbols:menu-rounded"
                          onClick={handleOpenDrawer}
                        />
                        <Logo height={32} width={80.33} data-testid="logo" />
                      </Stack>
                      <Drawer open={isOpen} anchor="top" onClose={handleOpenDrawer}>
                        <HeaderTabs permission={user?.permissao} />
                      </Drawer>
                    </>
                  ) : (
                    <Logo
                      height={32}
                      width={80.33}
                      data-testid="logo"
                      customLogo="/logo/logo_full_white.png"
                    />
                  )}
                </Badge>
              </Box>
              <Stack height="100%" sx={{ ml: 2, overflow: 'auto' }}>
                {responsive('up', 'sm') && <HeaderTabs permission={user?.permissao} />}
              </Stack>
              <Stack>
                <IconButton
                  size="small"
                  onClick={handleMenu}
                  data-testid="account-icon-button"
                  sx={{ color: 'common.white' }}
                >
                  <AccountCircle sx={{ width: '32px', height: '32px' }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  data-testid="account-menu"
                >
                  <MenuItem onClick={handleClose}>
                    <Typography variant="button">Perfil</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Typography variant="button">Minha Conta</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => setOpenConfirmDialog(true)}>
                    <Stack direction="row" spacing={2.5}>
                      <Typography variant="button">Logout</Typography>
                      <LogoutIcon sx={{ width: 20 }} />
                    </Stack>
                  </MenuItem>
                </Menu>
              </Stack>
            </Box>
            <ConfirmDialog
              title="Deseja mesmo deslogar?"
              open={openConfirmDialog}
              onClose={() => setOpenConfirmDialog(false)}
              onClickConfirm={handleLogout}
              onClickCancel={() => setOpenConfirmDialog(false)}
            />
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
