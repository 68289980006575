import SimpleBar from 'simplebar-react';

import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledRootScrollbar = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

export const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.6),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
    // TODO:
    // Acho que a biblioteca 'simplebar-react" não está preparado para receber um estilo para hover, mas pelo visto a biblioteca "simplebar" está.
    // https://github.com/Grsmto/simplebar/issues/229

    // Talvez dê pra meter um CSS puro aqui...
    // https://github.com/Grsmto/simplebar/issues/650
    // Ou simplesmente mudar a biblioteca.
    '& .simplebar-scrollbar:hover:before': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.8),
    },
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));
