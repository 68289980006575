import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { AxiosHttpClient } from 'src/http';
import { API_URL, OLD_API_URL } from 'src/config-global';
import { UserRepository } from 'src/repositories/user/user.repository';
import { AuthRepository } from 'src/repositories/auth/auth.repository';

import { FilterType } from 'src/components/search-filter/types';

import { Sort } from 'src/types/orders/sort';

// export interface UserType {
//   id: number;
//   nome: string;
//   email: string;
//   permissao: string;
//   status: string;
//   login?: string;
// }

// type postUserLoginParams = {
//   email: string;
//   password: string;
//   setSession: (accessToken: string, refreshToken: string, user: UserType) => void;
// };

type States = {
  isLoading: boolean;
  tabs: {
    label: string;
    value: string;
    count: number;
  }[];
  users: any;
  sort: Sort;
  sortBy: string;
  page: number;
  rowsPerPage: number;
  totalRows: number;
  filters: FilterType[];
  user: UserType | undefined;
  uf: any;
};

export type UserType = {
  id: number;
  nome: string;
  email: string;
  login: string;
  senha: string;
  permissao: string;
  status: string;
  password: string;
  uf?: number;
  setSession: (accessToken: string, refreshToken: string, user: UserType) => void;
  user?: UserType;
};

type Actions = {
  setSort: (sort: Sort) => void;
  setSortBy: (sortBy: string) => void;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setTotalRows: (totalRows: number) => void;
  setFilters: (filters: FilterType[]) => void;
  getUsers: () => Promise<void>;
  createUser: (user: any) => Promise<void>;
  editUser: (user: any) => Promise<void>;
  postUserLogin: ({ email, password, setSession }: postUserLoginParams) => Promise<void>;
  getUf: () => Promise<void>;
};

type postUserLoginParams = {
  email: string;
  password: string;
  setSession: (accessToken: string, refreshToken: string, user: UserType) => void;
};

type UserStore = Actions & States;

const initialValues: States = {
  isLoading: false,
  tabs: [
    { label: 'id', value: 'ID', count: 0 },
    { label: 'name', value: 'Nome', count: 0 },
    { label: 'email', value: 'Email', count: 0 },
    { label: 'login', value: 'Login', count: 0 },
    { label: 'permission', value: 'Permissão', count: 0 },
    { label: 'status', value: 'Status', count: 0 },
  ],
  users: [],
  sort: 'asc',
  sortBy: 'id',
  page: 0,
  rowsPerPage: 10,
  totalRows: 0,
  filters: [],
  user: undefined,
  uf: [],
};

const api = new AxiosHttpClient(API_URL);

const repository = new UserRepository(api);
const authRepository = new AuthRepository(api);

// const oldApi = new AxiosHttpClient(OLD_API_URL);
// const oldRepository = new UserRepository(oldApi);
// const auth = new AuthRepository(oldApi);

const oldApi = new AxiosHttpClient(OLD_API_URL);

const oldRepository = new UserRepository(oldApi);

export const useUserStore = create<UserStore>()(
  persist(
    (set, get) => ({
      ...initialValues,

      setSort: (sort: Sort) => set({ sort }),

      setSortBy: (sortBy: string) => set({ sortBy }),

      setPage: (page: number) => set({ page }),

      setRowsPerPage: (rowsPerPage: number) => set({ rowsPerPage }),

      setTotalRows: (totalRows: number) => set({ totalRows }),

      setFilters: (filters: FilterType[]) => set({ filters }),

      getUsers: async () => {
        set({ isLoading: true });
        const response = await oldRepository.getUsers();
        if (response.isLeft()) {
          set({ isLoading: false });
          throw new Error(response.value.message);
        }
        set({
          isLoading: false,
          users: response.value,
          totalRows: response.value.length,
        });
      },

      createUser: async (user: any) => {
        set({ isLoading: true });
        const response = await oldRepository.createUser(user);
        if (response.isLeft()) {
          set({ isLoading: false });
          throw new Error(response.value.message);
        }
        set({ isLoading: false });
      },

      editUser: async (user: any) => {
        set({ isLoading: true });
        const response = await oldRepository.editUser(user);
        if (response.isLeft()) {
          set({ isLoading: false });
          throw new Error(response.value.message);
        }
        set({ isLoading: false });
      },
      
      postUserLogin: async ({ email, password, setSession }: postUserLoginParams) => {
        set({ isLoading: true });
        const response = await authRepository.authLogin(email, password);
        if (response.isLeft()) {
          set({ isLoading: false });
          throw new Error(response.value.message);
        }

        // eslint-disable-next-line
        const { senha, ...userWithoutPassword } = response.value.user;
        setSession(response.value.access_token, response.value.refresh_token, userWithoutPassword);
        set({ user: userWithoutPassword });

        set({ isLoading: false });
      },

      postUserLogout: async (clearSession: () => void) => {
        const { user } = get();
        if (user) {
          const response = await authRepository.authLogout(user.id);
          if (response.isLeft()) {
            throw new Error(response.value.message);
          }
        }
        clearSession();
        set({ user: undefined });
      },

      getUf: async () => {
        const response = await repository.getUf();
        if (response.isLeft()) {
          throw new Error(response.value.message);
        }
        set({ uf: response.value });
      }
    }),
    {
      name: 'user-store',
      partialize: (state) => ({
        user: state.user,
      }),
    }
  )
);
