import { Either } from 'src/utils/either';

import { ApiError } from 'src/http/errors/api-error';
import { httpResponseHandler } from 'src/http/http-response-handler';

import { HttpTypes } from '../../http';

export class UserRepository {
  constructor(private api: HttpTypes.HttpClient) { }

  async getUsers(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `admin/`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async createUser(user: any): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: 'admin',
      body: {
        email: user.email,
        login: user.login,
        nome: user.nome,
        permissao: user.permissao,
        uf: user.uf,
        senha: user.senha,
        status: user.status,
      },
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async authLogin(email: string, password: string) {
    const request: HttpTypes.HttpRequest = {
      url: 'auth/login',
      method: 'post',
      body: {
        email,
        password,
      },
    };
    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async authLogout(userId: number) {
    const request: HttpTypes.HttpRequest = {
      url: `auth/logout/${userId}`,
      method: 'post',
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async editUser(user: any): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: 'admin',
      body: {
        email: user.email,
        id: user.id,
        login: user.login,
        nome: user.nome,
        permissao: user.permissao,
        senha: user.senha,
        status: user.status,
        uf: user.uf,
      },
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async verifyAccessToken(accessToken: string, userId?: number) {
    const request: HttpTypes.HttpRequest = {
      url: 'auth/verifyAccessToken',
      method: 'post',
      body: {
        accessToken,
        id: userId
      },
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async getUf() {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: 'location/states',
    }
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }
}