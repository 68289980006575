import { paths } from 'src/routes/paths';

export const BASE_URL = 'https://portal.dev.agyx.roxcode.io';
export const API_URL =  'https://backend.dev.agyx.roxcode.io';
export const OLD_API_URL = 'https://api.dev.agyx.roxcode.io';
export const SENTRY_DSN = 'https://635327bb76b5c57edf9ff06323fb2a52@o4506650949189632.ingest.us.sentry.io/4507420158853120';
export const ASSETS_API_URL = 'https://i.pravatar.cc/150?img=';
export const VIA_CEP_URL = 'https://viacep.com.br';

export const PATH_AFTER_LOGIN = paths.orders.root;

export const PATH_TO_REFRESH_TOKENS = paths.auth.refreshTokens;
